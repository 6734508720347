import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../context/authContext";
import {loginService} from "../services/employee";

const Login = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);


    const {setEmp, employeeI} = useAuth()
    const navigate = useNavigate();

    useEffect(() => {
        if (employeeI) navigate("/dashboard", { replace: true });
    }, []);

    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        await loginService({
            email: email,
            password: password,
        }).then((res) => {
            if (res) {
                setEmp({
                    fname: res.fname,
                    lname: res.lname,
                    mname: res.mname,
                    email: res.email
                });
                navigate("/dashboard", { replace: true });
            }
        }).catch((err) => {
            setError(err.error);
            console.log(err)
        })

        setLoading(false);

    }


    return (
        <div className={`w-full h-full`}>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-20">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        alt="Your Company"
                        src="https://kmcwebsite.nyc3.cdn.digitaloceanspaces.com/new%20logos/Asset%205.webp"
                        className="mx-auto h-10 w-auto"
                    />
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Sign in to your account
                    </h2>
                    {
                        loading && (
                            <p>Loading...</p>
                        )
                    }
                    {
                        error.length > 0 && (
                            <p>Invalid credentials...</p>
                        )
                    }

                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form onSubmit={submitHandler} className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => setEmail(e.target.value)}
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={email}
                                    required
                                    autoComplete="email"
                                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                                <div className="text-sm">

                                    <p className="font-semibold text-orange-600 hover:text-red-500 cursor-pointer">
                                        Forgot password?
                                    </p>
                                </div>
                            </div>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => setPassword(e.target.value)}
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={password}
                                    required
                                    autoComplete="current-password"
                                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                disabled={loading}
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                            >
                                Sign in
                            </button>
                        </div>
                    </form>


                        <Link to="/signup">
                            <p className="mt-10 text-center text-sm text-gray-500"> Not registered </p>
                        </Link>

                </div>
            </div>
        </div>
    );
};

export default Login;