import axios from "axios";
import {
    createContext,
    FC,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from "react";
import {Employee} from "../services/employee";

// Define types for the authentication context
interface AuthContextType {
    employeeI: Employee|undefined;
    setEmp: (emp: Employee|undefined) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [employeeI, setEmployee] = useState<Employee|undefined>(
        undefined
    );

    const setEmp = (emp:Employee|undefined) => {
        setEmployee(emp)
    }




    // Provide the authentication context to the children components
    return (
        <AuthContext.Provider
            value={{
                employeeI,
                setEmp
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

export default AuthProvider;
