import axios from "axios";
import {API_URL, X_API_KEY} from "../utils/api";

export type  Employee = {
    fname: string,
    mname?: string,
    lname: string,
    email: string,
};

export type AddEmployee = {
    fname: string,
    mname?: string,
    lname: string,
    email: string,
    password: string,
};

export type EmployeeLogin = {
    email: string,
    password: string,
}


export const loginService = async (cred: EmployeeLogin) => {
    try {
        const data = await axios
            .post<Employee>(API_URL + "login", {
                email: cred.email.trim(),
                password: cred.password.trim(),
            }, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
        return data;
    } catch (err) {}
};


export const signUpService = async (data_info: AddEmployee) => {
    try {
        const data = await axios
            .post<Employee>(API_URL + "signUp", data_info, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
        return data;
    } catch (err) {}
};


export const LogoutService = async () => {
    try {
        const data = await axios
            .post<string>(API_URL + "logout", {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
        return data;
    } catch (err) {}
};