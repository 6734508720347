import {Links} from "../layouts/Dashboard";
import {FC} from "react";

const Card:FC<{data: Links}> = ({data}) => {
    return (
        <a href={data.link} target="_blank" rel="noopener noreferrer">
            <div className="card">
                {data.icon}
                <h2>{data.title}</h2>
            </div>

        </a>
    );
};

export default Card;