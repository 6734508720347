import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../context/authContext";
import {LogoutService} from "../services/employee";

const Logout = () => {
    const navigate = useNavigate();
    const { setEmp } = useAuth();
    useEffect(() => {
        // localStorage.removeItem("token");
        setEmp(undefined);
        LogoutService().then()
        setTimeout(() => {
            navigate("/", { replace: true });
        }, 100);
    }, []);
    return null;
};

export default Logout;