import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Login from "./layouts/Login";
import Dashboard from "./layouts/Dashboard";
import Logout from "./layouts/Logout";
import SignUp from "./layouts/SignUp";

function App() {
  return (
      <Routes>
        <Route
            path={"/"}
            element={<Login />}
        />
        <Route
            path={"/login"}
            element={<Login />}
        />

          <Route
              path={"/signup"}
              element={<SignUp />}
          />

        <Route
            path={"/dashboard"}
            element={<Dashboard />}
        />
        <Route
            path={"/logout"}
            element={<Logout />}
        />
      </Routes>
  );
}

export default App;
